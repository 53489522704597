import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button, Form, message, Steps } from "antd";
import BasicDetails from "./steps/BasicDetails";
import BusinessDetails from "./steps/BusinessDetails";
import BankingInformation from "./steps/BankingInformation";
import SelectServices from "./steps/SelectServices";
import { axiosInstance } from "../../../controller/axiosInterceptor";
import CustomMobileHeader from "../../../common/mobile/CustomMobileHeader";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router";
import Footer from "../../../common/helper/footer/footer";
import { formatDate } from "../../../utils";
import StripeCardElements from "../payment/StripeCardElements";
import "./create-profile.scss";
import TermsAndConditionsContent from "../../../common/modals/auths/TermsAndConditionsContent";
import PrivacyPolicyContent from "../../../common/modals/auths/PrivacyPolicyContent";
import Drawer from "../../../common/drawer";

export default function ProfileStepper({ screenNo }) {
  const user = JSON.parse(localStorage.getItem("user"));
  const serviceObj = { title: "", price: "", imgUrl: "", description: "" };
  const [current, setCurrent] = useState(0);
  const [screen, setScreen] = useState(0);
  const [form] = Form.useForm();
  const [addedServices, setAddedServices] = useState([serviceObj]);
  const [categoryValue, setCategoryValue] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [visible, isVisible] = useState();
  const [visiblePrivacyPolicy, isVisiblePrivacyPolicy] = useState();
  const [loading, setLoading] = useState(false);
  const [adress, setAddress] = useState();
  const [showMap, setShowMap] = useState(false);
  const [businessPdf, setbusinessPdf] = useState("");
  const [phone, setphone] = useState();
  const [countryCode, setCountryCode] = useState([]);
  const [country, setCountry] = useState("");
  const [stripeError, setStripeError] = useState({
    cardNumber: "",
    cardExpiry: "",
    cardCvc: "",
  });
  const [checkFields, setCheckFields] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
    name: false,
  });
  const [cardData, setCardData] = useState({
    name: "",
  });

  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    const currentTab = JSON.parse(localStorage.getItem("currentTab"));
    if (currentTab) setCurrent(currentTab);
  }, []);

  useEffect(() => {
    if (screenNo == 0 || screenNo == 1 || screenNo == 2 || screenNo == 3) {
      setCurrent(screenNo);
    }
  }, [screenNo]);

  useEffect(() => {
    const userCounty = user?.address?.country;
    if (userCounty) {
      console.log("country", userCounty);
      setCountry(userCounty === "United States" ? "US" : "CA");
    }
  }, [user]);

  useEffect(() => {
    current && localStorage.setItem("currentTab", JSON.stringify(current));
  }, [current]);

  const next = () => {
    handleSubmit();
    setDisabled(true);
  };

  const showTermsConditionModal = (visible) => {
    isVisible(visible);
  };

  const showPrivacyPolicyModal = (visible) => {
    isVisiblePrivacyPolicy(visible);
  };

  const handleChange = async (event, cardinput) => {
    const e = await event;
    if (cardinput == "name") {
      setCardData({
        ...cardData,
        [e.target.name]: e.target.value,
      });
      if (e.target.value.length > 1) {
        setCheckFields({ ...checkFields, name: true });
      } else {
        setCheckFields({ ...checkFields, name: false });
      }
    } else {
      setCheckFields({ ...checkFields, [e.elementType]: e.complete });
      if (e.elementType === "cardNumber") {
        setStripeError({
          ...stripeError,
          cardNumber: e.error ? e.error.message : "",
        });
      } else if (e.elementType === "cardCvc") {
        setStripeError({
          ...stripeError,
          cardCvc: e.error ? e.error.message : "",
        });
      } else if (e.elementType === "cardExpiry") {
        setStripeError({
          ...stripeError,
          cardExpiry: e.error ? e.error.message : "",
        });
      }
    }
  };

  const generateBankToken = async () => {
    if (elements == null) {
      return;
    }

    try {
      const token = await stripe?.createToken("bank_account", {
        bank_account: {
          country: "US",
          currency: "usd",
          // swift_code: 'AIVVCATT',
          bank_name: form.getFieldValue("bank_name"),
          account_number: form.getFieldValue("account_number"),
          routing_number: form.getFieldValue("routing_number"),
        },
      });

      if (token.token) {
        const payload = {
          bankDetails: {
            token: token.token.id,
            name: form.getFieldValue("bank_name"),
            actNumber: form.getFieldValue("account_number"),
            swiftCode: form.getFieldValue("routing_number"),
          },
          tos_acceptance: {
            date: new Date().getTime().toString(),
            ip: "8.8.8.8",
          },
        };

        axiosInstance
          .put("/business/updateProfile", payload)
          .then(async () => {
            message.success("Bank Details Added Successfully..");
            setCurrent(3);
            await axiosInstance.put("/business/updateProfile", { screen: 3 });
            localStorage.setItem("screen", 3);
          })
          .catch((err) => message.error(err));
      }

      if (token.error) message.error(token.error.message);
    } catch (err) {}
  };

  const addDebitCard = async () => {
    try {
      if (elements == null) return;
      setLoading(true);
      const { token, error } = await stripe.createToken(elements.getElement(CardNumberElement), { currency: "cad" });

      if (error) {
        message.error(error.message);
        setLoading(false);
        return;
      }

      if (token.card.funding !== "debit") {
        message.error("Only debit card allowed");
        setLoading(false);
        return;
      }

      const payload = {
        debitCardDetails: { token: token?.id },
        tos_acceptance: {
          date: new Date().getTime().toString(),
          ip: "8.8.8.8",
        },
      };

      const res = await axiosInstance.put("/business/updateProfile", payload);
      if (res.data?.status === 200) {
        setLoading(true);
        setCurrent(3);
        await axiosInstance.put("/business/updateProfile", { screen: 3 });
        localStorage.setItem("screen", 3);
        elements?.getElement(CardNumberElement)?.clear();
        elements?.getElement(CardExpiryElement)?.clear();
        elements?.getElement(CardCvcElement)?.clear();
      }
    } catch (error) {
      setLoading(false);
      message.error(error?.response?.data?.error?.message);
    }
  };

  function capitalize(s) {
    return s ? s[0].toUpperCase() + s.slice(1) : "";
  }

  const handleSubmit = () => {
    const formData = form.getFieldsValue();
    if (current == 2 && country !== "CA") {
      generateBankToken();
    } else if (current == 2 && country === "CA") {
      addDebitCard();
    } else {
      let newObj = {};
      if (current == 0) {
        newObj["firstName"] = capitalize(formData.firstName);
        newObj["lastName"] = capitalize(formData.lastName);
        newObj["dob"] = formatDate(formData.dob, "YYYY-DD-MM");
      } else if (current == 1) {
        newObj["profileImage"] = formData.profileImage;
        if (formData.businessName) newObj["businessName"] = capitalize(formData.businessName);
        newObj["phoneNumber"] = phone;
        newObj["countryCode"] = `+${countryCode}`;
        newObj["taxId"] = formData.taxId;
        newObj["businessDocument"] = businessPdf;

        newObj["address"] = {
          province: adress?.region,
          city: adress?.city,
          postalCode: adress.postal_code,
          street: adress.address,
          country: adress?.country,
        };
        newObj["location"] = {
          type: "Point",
          coordinates: [parseFloat(adress.lon), parseFloat(adress.lat)],
        };
        console.log("adress?.country", adress?.country);
        setCountry(adress?.country === "United States" ? "US" : "CA");
      } else if (current == 2) {
        newObj["bankingDetails"] = {
          AccountName: formData.AccountName,
          AccountNumber: formData.AccountNumber,
          IFSCCode: formData?.IFSCCode,
        };
      }
      const newData = { ...newObj, screen: current + 1 };
      axiosInstance
        .put("/business/updateProfile", newData)
        .then(() => {
          if (current === 1 && !showMap) {
            setShowMap(true);
            setDisabled(false);
            localStorage.setItem("screen", current + 1);
          } else {
            setShowMap(false);
            setCurrent((prev) => prev + 1);
          }
        })
        .catch((err) => {
          message.error("Something went wrong..");
          setDisabled(false);
        });
    }
  };

  const handleFormChange = (value) => {
    setShowMap(false);

    if (value <= screen) {
      setCurrent(value);
    }

    if (value >= current) {
      const hasErrors = !form.isFieldsTouched(true) || form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
      setDisabled(hasErrors);
    } else {
      setDisabled(false);
      if (!screen) setScreen(current);
    }
  };

  const onlyTextHandler = (event) => {
    const charCode = event.which || event.keyCode;
    // Allow letters (A-Z and a-z) and spaces
    if (
      (charCode >= 65 && charCode <= 90) || // Uppercase letters
      (charCode >= 97 && charCode <= 122) || // Lowercase letters
      charCode === 32 // Space
    ) {
      // Allow the character
    } else {
      // Prevent any other characters
      event.preventDefault();
    }
  };

  const onlyNumbersHandler = (event) => {
    const charCode = event.which || event.keyCode;
    // Allow numbers (0-9)
    if (charCode >= 48 && charCode <= 57) {
      // Allow the character
    } else {
      // Prevent any other characters
      event.preventDefault();
    }
  };

  const scrollEffectHandler = (e) => {
    e.preventDefault();
    e.currentTarget.blur();
  };

  const getProfileData = () => {
    setLoading(true);
    const userData = {
      id: user?._id,
    };
    axiosInstance.post("/business/getProfile", userData).then((res) => {
      const country = res.data?.output?.businessData?.address?.country;
      if (country) {
        console.log("country getProfile", country);
        setCountry(country === "United States" ? "US" : "CA");
      }
      setLoading(false);
    });
  };

  const handleAddServices = () => {
    const params = {
      catId: categoryValue,
      serviceDetails: addedServices,
    };
    axiosInstance
      .post("/business/addService", params)
      .then(async () => {
        getProfileData();
        await axiosInstance.put("/business/updateProfile", { screen: 4 });
        localStorage.setItem("screen", 4);
        navigate("/availability");
        message.success("Services added successfully..");
        localStorage.removeItem("currentTab");
      })
      .catch((err) => message.error(err.data?.error?.message));
  };

  const steps = [
    {
      title: "Basic Details",
      content: <BasicDetails setDisabled={setDisabled} form={form} loading={loading} onlyTextHandler={onlyTextHandler} />,
    },
    {
      title: "Business Details",
      content: (
        <BusinessDetails
          showMap={showMap}
          setDisabled={setDisabled}
          form={form}
          onlyTextHandler={onlyTextHandler}
          scrollEffectHandler={scrollEffectHandler}
          setAddress={setAddress}
          adress={adress}
          setbusinessPdf={setbusinessPdf}
          onlyNumbersHandler={onlyNumbersHandler}
          setphone={setphone}
          phone={phone}
          setCountryCode={setCountryCode}
          countryCode={countryCode}
        />
      ),
    },
    {
      title: country === "CA" ? "Debit Card Information" : "Banking Information",
      content:
        country === "CA" ? (
          // <DebitCard form={form} setDisabled={setDisabled} />
          <div className="row amount-details-row pt-0">
            <div className="col-12 text-center steps-form-heading mb-0">
              <h5>Enter your debit card information</h5>
            </div>
            <div className="col-12 common-form-div form-create-profile">
              <StripeCardElements
                cardHoldersName={cardData.name}
                checkFields={checkFields}
                stripeError={stripeError}
                handleChange={handleChange}
                handleSubmit={addDebitCard}
              />
            </div>
          </div>
        ) : (
          <BankingInformation form={form} onlyTextHandler={onlyTextHandler} setDisabled={setDisabled} />
        ),
    },
    {
      title: "Select Services",
      content: (
        <SelectServices
          onlyTextHandler={onlyTextHandler}
          disabled={disabled}
          setDisabled={setDisabled}
          form={form}
          scrollEffectHandler={scrollEffectHandler}
          setCategoryValue={setCategoryValue}
          onlyNumbersHandler={onlyNumbersHandler}
        />
      ),
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const serviceValidate = () => {
    const inValid = addedServices?.filter((item) => !item?.title || !item?.price || !item?.imgUrl || !item?.description || !item?.subCatId);
    return inValid.length ? false : true;
  };

  return (
    <>
      <CustomMobileHeader />

      <div className="container-fluid create-profile-main">
        <div className="container">
          <div className="row create-profile-heading">
            <div className="col-12 text-center">
              <h1>Create Profile</h1>

              <p>Build your business profile. Unleash your potential, connect, and grow</p>
            </div>
          </div>
          <div className="row common-steps-row">
            <div className="col-12 steps-col">
              <Steps direction="vertical" current={current} items={items} onChange={handleFormChange} disabled={disabled} />
              <div className="steps-content">
                {steps[current]?.content &&
                  React.cloneElement(steps[current]?.content, {
                    form1Ref: form,
                    current,
                    addedServices,
                    categoryValue,
                    handleSubmit,
                    setAddedServices,
                    setCategoryValue,
                  })}
              </div>
            </div>
            <div className="steps-action steps-action-custom">
              <div className="row h-100 align-items-center justify-content-between container ">
                <Footer from />
                <div className="w-auto p-0 mobile-footer-fix">
                  {current < steps.length - 1 && (
                    <Button className={disabled ? "common-btn steps-btn" : "common-btn-dis "} disabled={disabled} onClick={() => next()}>
                      Next
                    </Button>
                  )}
                  {current === steps.length - 1 && (
                    <Button
                      className={
                        addedServices?.length > 0
                          ? serviceValidate() && !disabled
                            ? "common-btn-dis"
                            : "common-btn steps-btn"
                          : disabled
                          ? "common-btn steps-btn"
                          : "common-btn-dis"
                      }
                      disabled={addedServices?.length > 0 ? (serviceValidate() ? false : true) : disabled}
                      onClick={handleAddServices}
                    >
                      Next
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Drawer show={visible} onClose={() => showTermsConditionModal(false)}>
        <TermsAndConditionsContent slider />
      </Drawer>
      <Drawer show={visiblePrivacyPolicy} onClose={() => showPrivacyPolicyModal(false)}>
        <PrivacyPolicyContent slider />
      </Drawer>
    </>
  );
}
