import React, { useState } from 'react';
import { GoogleMap, MarkerF, useLoadScript } from '@react-google-maps/api';
import axios from 'axios';
import { getAddressObject } from './getAddressObject';
import { message } from 'antd';

// import InfoBoxComponent from './InfoBoxComponent';

const mapContainerStyle = {
  height: '330px',
  width: '100%',
  marginTop: '12px',
};

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_API;

const GoogleComponent = ({ lat, lon, zipcode, address, region, form, setAddress }) => {
  useLoadScript({
    googleMapsApiKey,
    // ...otherOptions
  });

  const [center, setCenter] = useState({ lat: parseFloat(lat), lng: parseFloat(lon) });

  console.log(center, 'center', lat, lon);

  const [markerPosition, setMarkerPosition] = useState(center);

  const mapClickHandler = () => {
    const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${address}${region}${zipcode}`;
    window.open(mapsUrl, '_blank');
  };

  const handleMarkerDrag = (event) => {
    form.setFieldsValue({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
    setMarkerPosition({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });

    getAddressFromCoordinates(event.latLng.lat(), event.latLng.lng())
  };

  const getAddressFromCoordinates = async (latitude, longitude) => {
    try {
      const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${googleMapsApiKey}`);
      setCenter({ lat: parseFloat(latitude), lng: parseFloat(longitude) });
      if (response.data?.results[0]) {
        const addressObj = getAddressObject(response.data?.results[0]?.address_components);
        const newAddObj = {
          address: addressObj.street || addressObj?.city,
          city: addressObj?.city,
          region: addressObj?.region,
          country: addressObj?.country,
          postal_code: addressObj?.postal_code,
          lat: latitude,
          lon: longitude,
        }
        setAddress(newAddObj);
        form.setFieldsValue('address', newAddObj);
      } else message.error('Internal server error')
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <GoogleMap
        id="circle-example"
        mapContainerStyle={mapContainerStyle}
        defaultOptions={{
          mapTypeControl: false,
          fullscreenControl: true,
        }}
        options={{
          clickableIcons: false,
          maxZoom: 15,
          minZoom: 1,
          restriction: { // this is for defines a boundary that restricts the area of the map accessible to users
            latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
          },
          // gestureHandling: "none", // this is for disable hand gesture
          // scrollwheel: false, // this is for disable Scroll Wheel
          // draggable: false, // this is for disable the Draggable
          fullscreenControl: false, // this is for disable the full screen view
          mapTypeControl: false, // this is for disable the map type select like satellite view or something
          streetViewControl: false, // this is for disable the street view control
          zoomControl: false, // this is for disable the default zoom control
          keyboardShortcuts: false, // this is for remove the keyboard shortcut button
          styles: [
            { elementType: "geometry", stylers: [{ color: "#d9e5cf" }] },
            { elementType: "labels.text.fill", stylers: [{ color: "#535942" }] },
            { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
            // boundaries of palace like city, state
            {
              featureType: "administrative",
              elementType: "geometry.stroke",
              stylers: [{ color: "#DDE9D3" }],
            },
            {
              featureType: "administrative.land_parcel",
              elementType: "geometry.stroke",
              stylers: [{ color: "#f5f1e6" }],
            },
            // land color
            {
              featureType: "landscape.natural",
              elementType: "geometry",
              stylers: [{ color: "#dee6d8" }],
            },
            // local place name like house, property
            {
              featureType: "poi",
              elementType: "geometry",
              stylers: [{ color: "#dee6d8" }],
            },
            {
              featureType: "poi",
              elementType: "labels.text.fill",
              stylers: [{ color: "#535942" }],
            },
            // local road
            {
              featureType: "road",
              elementType: "geometry",
              stylers: [{ color: "#f5f1e6" }],
            },
            {
              featureType: "road.arterial",
              elementType: "geometry",
              stylers: [{ color: "#f5f1e6" }],
            },
            {
              featureType: "road.local",
              elementType: "labels.text.fill",
              stylers: [{ color: "#9BA08F" }],
            },
            // main highway color
            {
              featureType: "road.highway.controlled_access",
              elementType: "geometry",
              stylers: [{ color: "#f5f1e6" }],
            },
            {
              featureType: "road.highway.controlled_access",
              elementType: "geometry.stroke",
              stylers: [{ color: "#DBCCA8" }],
            },
            // second highway color
            {
              featureType: "road.highway",
              elementType: "geometry",
              stylers: [{ color: "#f5f1e6" }],
            },
            {
              featureType: "road.highway",
              elementType: "geometry.stroke",
              stylers: [{ color: "#DBCCA8" }],
            },
            // vising place like airport or transit stop
            {
              featureType: "transit.line",
              elementType: "labels.text.stroke",
              stylers: [{ color: "#EDEDDF" }],
            },
            {
              featureType: "transit.station",
              elementType: "geometry",
              stylers: [{ color: "#EDEDDF" }],
            },
            // water color
            {
              featureType: "water",
              elementType: "geometry.fill",
              stylers: [{ color: "#CBDDC2" }],
            },
            // water palace label color
            {
              featureType: "water",
              elementType: "labels.text.fill",
              stylers: [{ color: "#9BA08F" }],
            },
          ]
        }}
        zoom={10}
        center={center}
      >
        <div className='bg-dark'>
          <MarkerF
            icon={{
              url: '/assets/icons/marker.svg',
            }}
            position={markerPosition}
            draggable={true}
            onDragEnd={handleMarkerDrag}
          />
        </div>
        <div
          onClick={mapClickHandler}
          style={{
            cursor: 'pointer',
            fontSize: '12px',
            position: 'absolute',
            fontWeight: 500,
            background: ' #d1c9c9',
            opacity: ' 0.7',
            color: ' #000000',
            padding: '0px 0px 0px 8px',
          }}
        >
          View larger map
        </div>
      </GoogleMap >
    </>
  );
};

export default GoogleComponent;